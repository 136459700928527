if (typeof WeakRef === "undefined") {
  class WeakRef<T extends object> {
    private target: T
    constructor(target: T) {
      this.target = target
    }

    deref(): T | undefined {
      return this.target
    }
  }
  ;(window as any).WeakRef = WeakRef
}
